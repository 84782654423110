<template>
  <div style="margin-top: 20px">
    <a-row type="flex" justify="center">
      <a-col :span="20">
        <a-row>
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" :xxl="18">
            <el-table @row-click="navigateToPersonaDetail" class="player_table" :default-sort = "{prop: 'soldier', order: 'descending'}" v-loading="personaListLoading" element-loading-background="rgba(0, 0, 0, 0)" row-key="uuid" default-expand-all :data="personaList" height="85vh" style="width: 100%;" :header-cell-style="{color: '#FFFFFF',height: '0'}" :cell-style="{color: '#FFFFFF', padding: '5px'}">
              <template #empty>
                <p class="emptyText" style="color: white">{{ personaListLoading ? '' : '暂无数据' }}</p>
              </template>
              <el-table-column label="头像" width="120" align="center">
                <template v-slot="scope">
                  <img width="60px" height="60px" :src="scope['row']['emblem'] ? scope['row']['emblem'] : DefaultValue.defaultAvatar" alt="">
                </template>
              </el-table-column>

              <el-table-column label="ID" min-width="100" show-overflow-tooltip>
                <template v-slot="scope">
                  <div style="font-size: 20px;font-weight: bold">
                    {{ scope['row']['platoonName'] ? '[' + scope['row']['platoonName'] + ']' : '' }} {{ scope['row']['personaName'] }}
                  </div>
                  <div style="color: #a6a6a6">
                    PID : {{ scope['row']['personaId'] }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </a-col>

          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" :xxl="{span:4,offset:1}" class="customScrollbar" style="overflow: auto; height: 100%; padding-top: 50px">
            <a-row type="flex" justify="space-between">
              <a-col :span="24">
                <div>
                  <el-radio-group style="width: 100%;" v-model="queryParams['searchMode']" @input="modeChange">
                    <el-radio-button style="width: 50%;" label="FUZZY">模糊搜索</el-radio-button>
                    <el-radio-button style="width: 50%;" label="ACCURATE">精确查询</el-radio-button>
                  </el-radio-group>
                </div>
                <el-input-number v-if="queryParams['searchMode'] === 'FUZZY'"  style="margin-top: 10px;width: 100%" class="transparentElement" v-model="queryParams['limit']" :step="1" step-strictly :min="1" :max="20" label="数量限制"/>
                <el-input spellcheck @keyup.enter.native="search" class="transparentElement" style="margin-top: 10px" v-model="queryParams['personaNameKeyword']" placeholder="输入关键字搜索"/>
                <div style="margin-top: 10px">
                  <a-button @click="search" :disabled="personaListLoading" style="height: 40px;" size="large" class="hoverButton" ghost block>查询玩家</a-button>
                  <a-button @click="resetQueryParams" style="height: 40px;margin-top: 10px" size="large" class="hoverButton" ghost block>重置筛选条件</a-button>
                </div>
                <div style="margin-top: 20px;text-align: center;color: white;">
                  共{{ personaList.length }}个用户
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {DefaultValue} from "../../enum/default-value";
import {batchQueryPersonaApi, queryPersonaApi} from "../../request/api/persona";

export default {
  name: "player",
  created() {
    this.$emit('changeKey', '3')
  },
  data() {
    return {
      DefaultValue: DefaultValue,
      queryParams: {
        personaNameKeyword: "",
        limit: 10,
        searchMode:"FUZZY",
      },
      personaList: [],
      currentPersona: null,
      personaListLoading: false,
    }
  },
  methods: {
    navigateToPersonaDetail(persona) {
      this.$router.push({
        path: "/player/info",
        query: {
          personaId: persona['personaId'],
          from: 3
        }
      })
    },
    resetQueryParams() {
      this.queryParams = {
        personaNameKeyword: "",
        limit: 10,
        searchMode:"FUZZY",
      }
    },
    modeChange(value) {
      if (value === 'FUZZY') {
        this.queryParams['limit'] = 10;
      }
    },
    search() {
      if (!this.queryParams['personaNameKeyword']) {
        this.$notify({
          title: '警告',
          message: "请输入玩家ID",
          type: 'warning'
        });
        return;
      }
      if (this.queryParams['searchMode'] === 'FUZZY') {
        this.batchQueryPersona();
      }
      else {
        this.queryPersona();
      }
    },
    queryPersona() {
      this.personaListLoading = true;
      queryPersonaApi(null, this.queryParams['personaNameKeyword']).then((res) => {
        this.personaList = [res.data.data]
      }).finally(() => {
        this.personaListLoading = false;
      })
    },
    batchQueryPersona() {
      this.personaListLoading = true;
      batchQueryPersonaApi(this.queryParams['personaNameKeyword'], this.queryParams['limit']).then((res) => {
        this.personaList = res.data.data
      }).finally(() => {
        this.personaListLoading = false;
      })
    },
    personaClick(row, col, event) {
      if (this.currentPersona === null) {
        this.currentPersona = row;
      } else {
        if (row['personaId'] === this.currentPersona['personaId']) {
          this.currentPersona = null;
        } else {
          this.currentPersona = row;
        }
      }
    }
  }
}
</script>

<style scoped>
::v-deep .transparentElement .el-input-number__decrease:hover {
  color: white;
  border-color: #e6a23c;
}
::v-deep .transparentElement .el-input-number__increase:hover {
  color: white;
  border-color: #e6a23c;
}
::v-deep .transparentElement .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #e6a23c;
}
::v-deep .transparentElement .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #e6a23c;
}
::v-deep .transparentElement .el-input-number__decrease {
  color: white;
  background-color: transparent;
}
::v-deep .transparentElement .el-input-number__increase {
  color: white;
  background-color: transparent;
}


::v-deep .el-table, ::v-deep .el-table__expanded-cell{
  background-color: transparent;
}
.player_table ::v-deep.el-table .el-table__row:hover {
  background-color: #f5f5f5; /* 设置悬停时的背景颜色 */
  cursor: pointer; /* 将鼠标样式设置为手型 */
}
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent;
}
::v-deep .el-table tbody tr:hover>td {
  background: rgb(255, 255, 255) !important;
  color: #000000 !important;
}

::v-deep .el-table tr,
::v-deep .el-table td {
  border-bottom: none !important;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}


/* 透明输入框 */
::v-deep .transparentElement .el-input__inner {
  /* 使input框的背景变透明 */
  background-color: transparent;
  /* 使边框也变透明 */
  border-color: white;
  /* 改变获取焦点后的竖线颜色 */
  caret-color: white;
  color:#FFFFFF;
}
::v-deep .transparentElement .el-input-number__decrease {
  color: white;
  background-color: transparent;
}
::v-deep .transparentElement .el-input-number__increase {
  color: white;
  background-color: transparent;
}
/* 透明输入框聚焦 */
::v-deep .transparentElement .el-input__inner:focus {
  border-color: #e6a23c;
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: transparent;
  color: white;
}

/* 按钮鼠标移入聚焦 */
.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled] {
  color: white;
}

::v-deep .el-radio-button__inner {
  width: 100%;
  background-color: transparent;
  border-color: white;
  font-weight: bold;
  box-shadow: none !important;
  color: white;
}

::v-deep .el-radio-button.is-active>.el-radio-button__inner {
  background-color: white;
  border-color: white;
  font-weight: bold;
  box-shadow: none !important;
  color: #4f4f4f;
}

::v-deep .el-loading-spinner .el-loading-text {
  color: white;
}
::v-deep .el-loading-spinner .path {
  stroke: white;
}
</style>