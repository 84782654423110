import request from "../axios";

export function queryPersonaStatsApi(personaId, personaName) {
    return request({
        url: "/persona/stats/query",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
        }
    })
}

export function queryPersonaApi(personaId, personaName) {
    return request({
        url: "/persona/query",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
        }
    })
}

export function batchQueryPersonaApi(personaNameKeyword, limit) {
    return request({
        url: "/persona/query/batch",
        method: 'GET',
        params: {
            persona_name_keyword: personaNameKeyword,
            limit: limit,
        }
    })
}

export function batchQueryPersonaRecentServerApi(personaId, personaName) {
    return request({
        url: "/persona/recent/server/query/batch",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
        }
    })
}

export function batchQueryPersonaMatchApi(personaId, personaName, pageNumber, pageSize) {
    return request({
        url: "/persona/match/query/batch",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
            page_number: pageNumber,
            page_size: pageSize
        }
    })
}

export function batchQueryPersonaFormerName(personaId, personaName) {
    return request({
        url: "/persona/name/former/query/batch",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
        }
    })
}

export function batchQueryPersonaWeaponApi(personaId, personaName, limit) {
    return request({
        url: "/persona/weapon/query/batch",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
            limit: limit
        }
    })
}

export function batchQueryPersonaVehicleApi(personaId, personaName, limit) {
    return request({
        url: "/persona/vehicle/query/batch",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
            limit: limit
        }
    })
}